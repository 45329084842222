import { Favorite } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import DirectInteractionActionCreator from 'actions/DirectInteractionActionCreator';
import NearbyActionCreator from 'actions/NearbyActionCreator';
import Config from 'config';
import { isError } from 'lodash';
import { REGISTER_USER } from 'models/Paths';
import { IRelation } from 'models/user/IRelation';
import { IStrangerUser } from 'models/user/IStrangerUser/IStrangerUser';
import { IStrangerUserPreview } from 'models/user/IStrangerUser/IStrangerUserPreview';
import { IUser } from 'models/user/IUser';
import { useCallback, useEffect, useState } from 'react';
import { batch, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ResourceService from 'services/i18n';
import useTranslation from 'services/i18n/core/useTranslation';
import NotificationActionCreator from 'services/Notifications/actions/NotificationActionCreator';

export interface ILikeButtonProps {
    profilId: string;
    strangerUser: IStrangerUserPreview | IStrangerUser;
    isLiked?: boolean;
    token?: string;
    user?: IUser;
}

const StrangerProfileBtn = (props: ILikeButtonProps) => {
    const { profilId, strangerUser, token, user } = props;

    const [isLiked, setisLiked] = useState<boolean>(props.isLiked || false);
    const [isMatch, setIsMatch] = useState<boolean>(false);
    const [isMatchModalOpen, setIsMatchModalOpen] = useState<boolean>(false);
    const history = useHistory();

    const dispatch = useDispatch();
    const { MATCHED, LIKE_SUCCESS } = useTranslation();

    useEffect(() => {
        if (props.isLiked) {
            setisLiked(props.isLiked);
        }
    }, [props.isLiked]);

    const handleLikeClick = useCallback(async () => {
        if (!user && !token) {
            history.push(REGISTER_USER, { from: location.pathname });
            return;
        }

        const action = isLiked ? 'dislike' : 'like';
        const res = await DirectInteractionActionCreator.triggerStrangerUserRelation(profilId, action, token, user);

        if (res.ok) {
            const [response]: any = (await res.json()) as [IRelation];

            setisLiked(!isLiked);

            if (!isLiked && response.isMatch) {
                setIsMatchModalOpen(true);
                setIsMatch(true);
            } else if (!isError(res) && response['Note'] === 'Success') {
                batch(() => {
                    dispatch(NearbyActionCreator[action + 'StrangerUser'](profilId));

                    dispatch(
                        NotificationActionCreator.enqueueSnackbar({
                            key: `${action}-${profilId}`,
                            message: ResourceService.replace(LIKE_SUCCESS, { name: strangerUser.Username }),
                            options: { variant: 'default' },
                        })
                    );
                });
            }
        }
    }, [profilId, token, user, dispatch, LIKE_SUCCESS, strangerUser.Username, isLiked]);


    // try {
    //     Logger.log(<MatchDialog open onClose={() => setIsMatch(false)} user={user} strangerUser={strangerUser} />);
    // } catch (e) {
    //     Logger.warn(e);
    // }

    return (
        <Box>
            <Button sx={{ ...BtnStyle, backgroundColor: isLiked ? Config.GLOBAL_PRIMARY_COLOR : 'white' }} onClick={handleLikeClick}>
                <Favorite sx={{ color: isLiked ? 'white' : Config.GLOBAL_PRIMARY_COLOR }} />
            </Button>
        </Box>
    );
};

export default StrangerProfileBtn;

const BtnStyle = {
    backgroundColor: 'white',
    borderRadius: 100,
    height: '65px',
    width: '65px',
};
